.App {
  font-family: 'Roboto', sans-serif;
}

.App-header {
  font-family: 'Roboto Black', sans-serif;
  background: #333A40;
  padding: 1rem;
  color: #FFFFFF;
}

.App-header--link,
.App-header--link:hover,
.App-header--logo {
  color: #FFFFFF;
  text-decoration: none;
  font-family: 'Gravitas One', cursive;
  font-weight: 900;
}

.App-highlight .container {
  max-width: 40rem;
}